<template>
 <div>
   <b-row>
     <b-col md="12">
        <b-card
          no-body
          class="p-1"
        >
        <b-card-title class=" mb-2">{{$t('tabs.userhistory')}}</b-card-title> 
            <b-row>
              <b-col md="3"> 
                <b-form-group
                  label="Date from"
                  label-for="datefrom"
                  rules="required"
                >
                <template #label>{{$t('labels.datefrom')}}</template>
                <flat-pickr
                    v-model="dateFrom"
                    class="form-control"
                    :config="{altInput: true, altFormat: 'Y-m-d' , enableTime: false }"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Date To"
                  label-for="dateto"
                  rules="required"
                >
                <template #label>{{$t('labels.dateto')}}</template>
                <flat-pickr
                    v-model="dateTo"
                    class="form-control"
                    :config="{altInput: true, altFormat: 'Y-m-d' , enableTime: false}"  
                  />
                </b-form-group>
              </b-col>
      
              <b-col md="3" class="">
                <b-form-group
                  label="-"
                  label-for="search"
                >
               <b-button variant="primary" @click="getWalletsTransactions" >{{$t('buttons.search')}}</b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                  <b-form-group
                    label="Search Username"
                    label-for="username"
                  >
                    <template #label>{{$t('labels.search')}}</template>
                    <b-form-input
                      id="username"
                      v-model="searchQuery"
                    />
                  </b-form-group>
              </b-col>
            </b-row>
            <br>
            <b-overlay :show="loadindData">
              <div>
                <div>
                  <b-table  
                    small
                    :items="items"
                    :fields="fields"
                    :search-options="{
                      enabled: true,
                      externalQuery: searchUser,
                    }"
                  >
                    <template #cell(date)>
                      --
                    </template>
                     
                    <!-- 
                    <template #cell(Fecha)="data">
                      {{data.item.id | moment('MM/DD/YYYY')}}
                    </template>
                    -->
                    <template #cell(reference)="data">
                      {{data.item.reference}}
                    </template>

                    <template #cell(provider)="data">
                      <span><b>{{data.item.provider}}</b></span>
                    </template>
                    
                    <template #cell(description)="data">
                      {{data.item.description}}
                    </template>
                    
                    <template #cell(operation)="data">
                     <span v-if="data.item.operation == 'credit'" class="text-success text-nowrap">{{data.item.operation}}</span>
                     <span v-else class="text-danger text-nowrap">{{data.item.operation}}</span>
                    </template>
                    
                    <template #cell(amount)="data">
                      {{data.item.amount}}
                    </template>
                    
                    <template #cell(balance_before)="data">
                      <span >{{data.item.balance_before}}</span>
                    </template>
                                   
                    <template #cell(balance_after)="data">
                      <span><b>{{data.item.balance_after}}</b></span>
                    </template>

                  </b-table>
                </div>
              </div>
            </b-overlay>

        </b-card>
     </b-col>

   </b-row>
 </div>
</template>


<script>

import {
  BCard, BCardHeader, BCardBody, BCollapse, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BButton, BAlert, BFormCheckbox, BCardText, BOverlay, BCardTitle
} from 'bootstrap-vue'
import { BTable } from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted} from '@vue/composition-api'
import 'vue-tree-halower/dist/halower-tree.min.css'
import { VTree, VSelectTree } from 'vue-tree-halower'
import userStoreModule from '../../apps/user/userStoreModule'
import clientStoreModule from '../clients/clientStoreModule'
import whitelabelStoreModule from '../whitelabel/whitelabelStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import HierarchyUsers from '@core/components/HierarchyUsers/HierarchyUsers.vue'


const fechaActual = new Date();

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    VSelectTree,
    vSelect,
    VTree,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    flatPickr,
    HierarchyUsers
  },
  data() {
    return {
      items: [],
      fields: [
        {key: "date", label: this.$t('labels.date') },
        {key: "reference", label: this.$t('labels.reference')},
        {key: "provider", label: this.$t('labels.operator') },
        {key: "description", label: this.$t('labels.description') },
        {key: "operation", label: this.$t('labels.operation') },
        {key: "amount", label: this.$t('labels.import') },
        {key: "balance_before", label: this.$t('labels.previousbalance') },
        {key: "balance_after", label: this.$t('labels.nextbalance')}
      ],
      dateFrom: '2022-01-10', // new Date(fechaActual.setMonth(fechaActual.getMonth()-1)),
      dateTo: '2022-05-10',  //new Date(),
      TransactionsWallet: "",
      currency: "USD",
      perPage: 20,
      page: 1,
      sortDesc: true,
      q: "",
      idUserSelect:"626b28eb2f4f4a124c8fa02c",
      rangeDate: "",
      loadindData: false,
    }
  },
  watch: {
    
  },

  filters: {
    moment: function (date) {
      return moment(date).format('MM/DD/YYYY hh:mm')
    }
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const CLIENT_APP_STORE_MODULE_NAME = 'app-client'
    const WL_APP_STORE_MODULE_NAME = 'app-whitelabel'
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule)
    if (!store.hasModule(WL_APP_STORE_MODULE_NAME)) store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule)
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME)
    })
  
  },
  methods: {
    //jeanrosa metodo que activa el fetchsTransactions en el clientStoredModule.js
    async getWalletsTransactions(ctx, callback){
      try{
        this.TransactionsWallet = await store.dispatch('app-client/fetchsTransactions', {
          perPage: this.perPage, //numero de registro por pagina 
          page: this.page, //pagina actual
          sortDesc: this.sortDesc,
          q: this.q,
          id: this.idUserSelect, //Usuario actual conectado
          currency: this.currency,// tomar del currency que esté en el formulario
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        })
        this.items = this.TransactionsWallet.data.data
      }catch(error){
        console.error(error);
      }
    },
    
  },
  created () {
    
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.tree-block{
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected{
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border:1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}

[dir] .table th, [dir] .table td {
    padding: 0.72rem 0.7rem;
}
</style>